/* You can add global styles to this file, and also import other style files */

@import "exports";
@import "~@ng-select/ng-select/themes/default.theme.css";
@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url('assets/fonts/avenirnextltpro-bold-webfont.woff2') format('woff2'),
       url('assets/fonts/avenirnextltpro-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url('assets/fonts/avenirnextltpro-demi-webfont.woff2') format('woff2'),
       url('assets/fonts/avenirnextltpro-demi-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'AvenirNextLTPro-Medium';
  src: url('assets/fonts/avenirnextltpro-medium-webfont.woff2') format('woff2'),
       url('assets/fonts/avenirnextltpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url('assets/fonts/avenirnextltpro-regular-webfont.woff2') format('woff2'),
       url('assets/fonts/avenirnextltpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  background: $grey-bg-light;
  font-family: 'Open Sans', sans-serif;
}

.bold {
  font-weight: 900;
}

.block {
  max-width: 800px;
  padding: 24px 32px 32px 32px;

  @include media-breakpoint-down(sm) {
    padding: 2.5rem;
  }

  &__edit {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: $invalid;
    background: none;

    &:hover {
      color: $primary;
    }
  }

  .inst-centered {
    color: $instructive;
    text-align: center;
    max-width: 640px;
    margin: 0 auto 1.5rem;
  }

  section {
    margin-bottom: 30px;
  }

  .section-title {
    color: $secondary;
    font-family: 'AvenirNextLTPro-Regular' ;
  }
}
.main-instruction{
  margin-bottom: 16px;
  font-family: 'AvenirNextLTPro-Demi';
  color: $primary-dark;
  margin-bottom: 16px;
  padding: 0;
  line-height: 24px;
}

.modal-backdrop {
  background-color: #c7c7c7;
}

.modal-content {
  border: none;
  padding: 4rem 4rem 2.5rem;
  @include block-shadow;

  &.compact {
    padding: 2rem;
  }
}

.modal-footer {
  display: block;
  padding: 0;
  border-top: none;
  text-align: center;
  padding-top: 3rem;

  &__cta {
    margin: 0 !important;
  }

  &__link {
    margin: .75rem 0 0 !important;
    padding: .25rem 0;
  }
}

ngb-datepicker {
  @include block-shadow;
  border: none !important;
  margin-top: .25rem !important;

  .outside.text-muted {
    color: $invalid !important;
  }

  .custom-select {
    background-color: transparent;
    font-weight: 900;
    font-size: 1rem !important;
    border: none;
    cursor: pointer;
    &:focus {
      box-shadow: none;
    }
  }

  .ngb-dp-header {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
    width: 2.5rem !important;

    .btn-light {
      width: 2.5rem !important;
    }
  }

  .ngb-dp-weekdays {
    border-bottom: none !important;
  }

  .btn-light:focus {
    box-shadow: none;
  }
}

input[ngbdatepicker] {
  cursor: pointer;
}

.bs-tooltip-top, .bs-tooltip-bottom {
  animation: 200ms fadeIn ease-in-out;
  pointer-events: none;
}

.break-word {
  overflow-wrap: anywhere;
}

.info-narrative {
  font-family: $font-family-sans-serif;
  background: #f1f5fb;
  color: $instructive;
  padding: 12px;
  border-radius: 5px;
}

.text-with-tooltip {
  border-bottom: dashed 1px;
  color: $primary;
}
button{
  &:disabled{
    background: #C7C7C7 !important;
    border: 1px solid #C7C7C7 !important;
    cursor: not-allowed !important;
  }
}
.form-group{
  label{
    font-size: 12px;
    text-transform: uppercase;
    color: #6C757D;
    line-height: 18px;
    letter-spacing: 1px;
    .form-error{
      text-transform: capitalize;
    }
  }
}
.form-control{
  font-size: 16px;
  color: #363636;
  line-height: 24px;
  font-weight: 400;
  ::placeholder{
    color: #C7C7C7;
  }
}