@mixin bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

@mixin theme-gradient {
  background: linear-gradient(90deg, $violet, $primary);
}

@mixin block-shadow {
  background: $white;
  box-shadow: 0 8px 14px 1px rgba(201,201,201,0.27), 0 0 4px 0 rgba(179,179,179,0.30);
  border-radius: 12px;
}
