@keyframes loading-dot {
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

html {
  font-size: 87.5%;
}

a, a:not([href]):not([tabindex]) {
  color: $primary;
  &:hover {
    cursor: pointer;
    color: darken($primary, 10%);
    text-decoration: none;
  }
}

button:focus {
  outline: none;
}

.main-instruction {
  color: $instructive;
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 9px;
  padding: 0 5px;
}

.block {
  @include block-shadow;
  position: relative;
  padding: 36px;
  select {
    padding-right: 1.6rem!important;
    &:required:invalid {
      color: $invalid;

      option {
        color: $dark;
      }
    }
    option[value=''][disabled] {
      display: none;
      color: $invalid;
    }
  }
}

select.form-control {
  padding-right: 1.6rem!important;
  &:required:invalid {
    color: $invalid;
    option {
      color: $dark;
    }
  }
  option[value=''][disabled] {
    display: none;
    color: $invalid;
  }
}

input.form-control, select.form-control, button.form-control {
  background-color: $grey-bg;
  border-color: $grey-bg;
  &.ng-invalid.ng-touched {
    background-color: $danger-bg;
    border-color: $danger-bg;
  }
  &::placeholder {
    color: $invalid;
  }
  &:hover {
    background-color: $primary-bg;
    border-color: $primary-bg;
  }
}

input.form-control:focus {
  box-shadow: 0 0 0 .5px $primary;
  border-color: $primary;
  background-color: $primary-bg;
  &.ng-invalid.ng-touched {
    box-shadow: 0 0 0 .5px $danger;
    border-color: $danger;
  }
}

.section-title {
  color: $instructive;
  font-size: 14px;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 24px;
  width: 100%;
}

.form-group {
  label {
    display: flex;
    margin-bottom: 0.2rem;
    padding-left: 2px;
    align-items: center;
   .form-error {
      margin-left: auto;
      font-size: 12px;
      color: $danger;
    }
  }
  .helper-link {
    display: block;
    color: $primary;
    text-align: right;
    margin-top: 0.3rem;
  }
}

.btn {
  &.btn-cta {
    min-width: 150px;
    padding: 0.5rem 3rem;
    @include bold;
  }
  &.btn-primary:disabled {
    background: $invalid;
    cursor: not-allowed;
  }
  &:focus, &:active {
    box-shadow: none;
  }
  &.loading {
    pointer-events: none;
    position: relative;
    span {
      color: transparent;
    }
    .loading-dots {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      span {
        display: inline-block;
        background: #fff;
        width: 10px;
        height: 10px;
        border-radius: 1rem;
        animation-name: loading-dot;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 0.5s;
        margin: 0 5px;
        transition: all 0.6s;
      }
      .loading-dot-2 {
        animation-delay: 0.2s;
      }
      .loading-dot-3 {
        animation-delay: 0.4s;
      }
    }
  }
}

.warning-message {
  display: flex;
  background: $warning-light;
  border-radius: 5px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  overflow: hidden;
  .warning-icon {
    color: $warning;
    font-size: 1.5rem !important;
    margin-right: 0.5rem;
  }
  p {
    font-size: 0.9rem;
    margin: 0;
  }
}

.input-wrapper {
  position: relative;
  button.right {
    font-size: 1.4rem;
    background: none;
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    cursor: pointer;
  }
  &:before, &:after {
    display: block;
    position: absolute;
    pointer-events: none;
    top: 1px;
    z-index: 10;
    line-height: 34px;
    color: #C7C7C7;
    @include bold;
  }
  &:before {
    left: 10px;
  }
  &:after {
    right: 10px;
  }
  &.percent-input:after {
    content: '%';
  }
  .prefix, .postfix {
    color: $invalid;
    position: absolute;
    pointer-events: none;
    top: calc(50% - .5rem);
    line-height: 1rem;
  }
  .prefix {
    left: .75rem;
    & + .form-control {
      padding-left: 2.5rem;
    }
  }
  .postfix {
    right: .75rem;
  }
}
